<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Response">
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row
            v-for="(round, index) in program.rounds"
            :key="index"
            class="mb-2"
          >
            <b-col>
              <b-form-group
                :label="`Round`"
                :label-for="`roundName-${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Round Name"
                >
                  <h4 :id="`roundName-${index}`">
                    {{ round.title }}
                  </h4>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row class="ml-25 border-left">
                <b-col md="12">
                  <b-row
                    v-for="(question, qindex) in round.question"
                    :key="qindex"
                    class="mb-2"
                  >
                    <b-col md="12">
                      <b-col>
                        <b-form-group
                          :label="`Section: ${question.section}`"
                          :label-for="`question-${qindex}`"
                        >
                          <h4 :id="`question-${qindex}`">
                            {{ question.title }}
                          </h4>
                          <p :id="`answer-${qindex}`">
                            <b> Answer:</b> {{ question.answer }}
                          </p>
                          <b-form-input
                            :id="`score-${qindex}`"
                            v-model="question.score"
                            style="position: relative; width: 200px"
                            type="number"
                            placeholder="Score"
                            readonly="true"
                            required
                          />
                          <hr>
                        </b-form-group>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-row class="ml-25 border-left">
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    @click="$router.back()"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="ChevronLeftIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow, BCard,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
  },
  data() {
    return {
      inputVariable: {
        scoreTable: [],
      },
      programValues: '',
      program: {
        rounds: [
          {
            title: '',
            question: [
              {
                id: '',
                title: 'Getting questions data...',
                section: 'Please wait...',
                answer: '',
                answerType: '',
                answer_id: '',
                score: '',
              },
            ],
          },
        ],
      },
    }
  },
  watch: {
    programValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setApplicationModel()
      }
    },
  },
  methods: {
    setApplicationModel() {
      if (this.$route.params.apid) {
        for (let i = 0; i < this.programValues.length; i += 1) {
          this.program.rounds[i].title = this.programValues[i].title
          if (i !== this.programValues.length - 1) {
            this.program.rounds.push(
              {
                title: '',
                question: [
                  {
                    id: '',
                    title: '',
                    section: '',
                    answer: '',
                    answer_id: '',
                    answerType: '',
                    score: '',
                  },
                ],
              },
            )
          }
          for (let j = 0; j < this.programValues[i].programs_applicationsquestionstables.length; j += 1) {
            this.program.rounds[i].question[j].title = this.programValues[i].programs_applicationsquestionstables[j].question
            this.program.rounds[i].question[j].section = this.programValues[i].programs_applicationsquestionstables[j].section
            this.program.rounds[i].question[j].answerType = this.programValues[i].programs_applicationsquestionstables[j].answer_type
            this.program.rounds[i].question[j].id = this.programValues[i].programs_applicationsquestionstables[j].id
            this.program.rounds[i].question[j].answer = this.programValues[i].programs_applicationsquestionstables[j].programs_applicationresponsetables[0].response
            this.program.rounds[i].question[j].answer_id = this.programValues[i].programs_applicationsquestionstables[j].programs_applicationresponsetables[0].id
            this.program.rounds[i].question[j].score = this.programValues[i].programs_applicationsquestionstables[j].programs_applicationresponsetables[0].programs_applicationdirectscores[0].score
            if (j !== this.programValues[i].programs_applicationsquestionstables.length - 1) {
              this.program.rounds[i].question.push(
                {
                  id: '',
                  title: '',
                  section: '',
                  answer: '',
                  answer_id: '',
                  answerType: '',
                  score: '',
                },
              )
            }
          }
        }
      }
    },
  },
  apollo: {
    programValues: {
      query() {
        return gql`
        {
            programs_applicationformstable(where: {id: {_eq: ${this.$route.params.apid}}}) {
              round_number
              title
              programs_applicationsquestionstables {
                id
                answer_type
                question
                section
                programs_applicationresponsetables(where: {applicant_id: {_eq: ${this.$route.params.aid}}}) {
                  id
                  response
                  programs_applicationdirectscores(where: {partner_id: {_eq: ${this.$route.params.partner}}}) {
                    score
                  }
                }
              }
            }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
